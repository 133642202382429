import * as React from 'react';
import { HeadFC, PageProps } from 'gatsby';

import Layout from '../components/Layout';
import Definitions from '../components/Agreement/Definitions';
import Interpretation from '../components/Agreement/Interpretation';
import Loading from '../components/Loading';

import {
  TERMS_DEFINITIONS,
  TERMS_DEFINITION_CONTENT,
  TERMS_INTERPRETATION_CONTENT,
} from '../constants/terms-and-conditions';

import {
  termsContainer,
  interpretHeader,
  loadingWrapper,
} from '../styles/pages/terms-and-conditions/styles.module.css';
import Footer from '../components/Footer';

import { useAnimationLoading } from '../hooks/use-animation-loading-query';

const TermsAndConditionsPage: React.FC<PageProps> = () => {
  const loadingData = useAnimationLoading();

  return (
    <Layout>
      <div className={loadingWrapper}>
        <Loading data={loadingData} />
      </div>
      <main className={termsContainer}>
        <h1>Terms and Conditions</h1>
        <p>Last updated: December 15, 2022</p>
        <p>
          Please read these terms and conditions carefully before using Our
          Service.
        </p>
        <h1 className={interpretHeader}>Interpretation and Definitions</h1>
        <Interpretation interpretationsContent={TERMS_INTERPRETATION_CONTENT} />
        <Definitions
          definitions={TERMS_DEFINITIONS}
          paragraphContent={TERMS_DEFINITION_CONTENT}
        />
      </main>
      <Footer />
    </Layout>
  );
};

export default TermsAndConditionsPage;

export const Head: HeadFC = () => <title>Terms and Conditions</title>;
