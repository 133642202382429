import * as React from 'react';

import { container, interpretationParagraph } from '../styles.module.css';

type InterpretationsType = {
  interpretationsContent: {paragraph: string};
};

const Interpretation = ({
  interpretationsContent,
}: InterpretationsType): JSX.Element => {
  return (
    <div className={container}>
      <h2>Interpretation</h2>
      <p className={interpretationParagraph}>
        {interpretationsContent.paragraph}
      </p>
    </div>
  );
};

export default Interpretation;
