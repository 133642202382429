import { graphql, useStaticQuery } from 'gatsby';

export const useAnimationLoading = () => {
  const { prismicLoadinganimation } = useStaticQuery(graphql`
    query LoadingAnimation {
      prismicLoadinganimation {
        data {
          loading_animation_image {
            gatsbyImageData
            alt
          }
          loading_animation_text {
            richText
          }
        }
      }
    }
  `);

  return prismicLoadinganimation?.data;
};
