export const TERMS_DEFINITION_CONTENT = {
  paragraph: 'For the purposes of these Terms and Conditions:',
};

export const TERMS_INTERPRETATION_CONTENT = {
  paragraph:
    'The words of which the initial letter is capitalized have meanings defined under the following conditions. The following definitions shall have the same meaning regardless of whether they appear in singular or in plural.',
};

export const TERMS_DEFINITIONS = [
  {
    id: 1,
    heading: 'Affiliate',
    desc: ' means an entity that controls, is controlled by or is under common control with a party, where "control" means ownership of 50% or more of the shares, equity interest or other securities entitled to vote for election of directors or other managing authority.',
  },
  {
    id: 2,
    heading: 'Country',
    desc: ' refers to: Philippines',
  },
  {
    id: 3,
    heading: 'Company',
    desc: ' (referred as either “the Company”, “We”, “Us” or “Our” in this Agreement) referes to Mugna Tech, 3rd floor Lot 8 Block 6, Turqouise St., Marfori Subd, Davao City, 8000, Davao del Sur, Philippines.',
  },
  {
    id: 4,
    heading: 'Device',
    desc: ' means any device that can access the Service such as a computer, a cellphone or a digital tablet.',
  },
  {
    id: 5,
    heading: 'Service',
    desc: ' refers to the Website.',
  },
  {
    id: 6,
    heading: 'Terms and Conditions',
    desc: ' (also referred as "Terms") mean these Terms and Conditions that form the entire agreement between You and the Company regarding the use of the Service. This Terms and Conditions agreement has been created with the help of the TermsFeed Terms and Conditions Generator.',
  },
  {
    id: 7,
    heading: 'Third-party Social Media Service',
    desc: ' means any services or content (including data, information, products or services) provided by a third-party that may be displayed, included or made available by the Service.',
  },
  {
    id: 8,
    heading: 'You',
    desc: ' means the individual accessing or using the Service, or the company, or other legal entity on behalf of which such individual is accessing or using the Service, as applicable.',
  },
];
