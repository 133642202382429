import React, { useState } from 'react';
import { Link } from 'gatsby';
import {
  footerContainer,
  footerContactContainer,
  footerList,
  footerItem,
  footerItemScale,
} from '../../styles/components/Footer/styles.module.css';
import { FOOTER_ITEMS } from '../../constants/footer';
import ContactModal from '../ContactModal';
import { set } from 'lodash';

const Footer = () => {
  const [isContactModalOpen, setIsContactModalOpen] = useState(false);
  const handleContactModalCLose = () => {
    setIsContactModalOpen(false);
  };

  //TODO: Need to refactor isHover function to custom hook useHover
  const [isHover, setIsHover] = useState({});
  const [isContactHover, setIsContactHover] = useState(false);

  const mouseOver = (event: any, index: number) => {
    setIsHover((prevValue) => {
      return {
        ...prevValue,
        [index]: true,
      };
    });
  };

  const mouseOut = (event: any, index: number) => {
    setIsHover((prevValue) => {
      return {
        ...prevValue,
        [index]: false,
      };
    });
  };

  return (
    <div className={footerContainer}>
      <div className={footerContactContainer}>
        <ul className={footerList}>
          {FOOTER_ITEMS?.map((item, index) => (
            <li
              className={isHover[index] ? footerItemScale : footerItem}
              key={item.id}
              onMouseEnter={(e) => {
                mouseOver(e, index);
              }}
              onMouseLeave={(e) => {
                mouseOut(e, index);
              }}
            >
              <Link to={item?.path}>{item?.name}</Link>
            </li>
          ))}
          <li
            className={isContactHover ? footerItemScale : footerItem}
            onMouseEnter={() => setIsContactHover(!isContactHover)}
            onMouseLeave={() => setIsContactHover(!isContactHover)}
            onClick={() => setIsContactModalOpen(true)}
          >
            Contact Us
          </li>
        </ul>
        {isContactModalOpen && (
          <ContactModal closeModal={handleContactModalCLose} />
        )}
      </div>
    </div>
  );
};

export default Footer;
