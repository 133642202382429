import * as React from 'react';
import {
  loadingContainer,
  loadingImg,
  onpointName,
  loadingAnimationImage,
} from './styles.module.css';
import { GatsbyImage } from 'gatsby-plugin-image';
import { PrismicRichText } from '@prismicio/react';

import { LoadingAnimationData } from '../../interfaces/loading-animation';

const Loading = ({ data }: LoadingAnimationData) => {
  const { loading_animation_text } = data;
  const loadingAnimationLogo = data?.loading_animation_image?.gatsbyImageData;
  const loadingAnimationAlt = data?.loading_animation_image?.alt;

  return (
    <div className={loadingContainer}>
      <div className={loadingImg}>
        <GatsbyImage
          image={loadingAnimationLogo}
          alt={loadingAnimationAlt}
          className={loadingAnimationImage}
        />
      </div>
      <p className={onpointName}>
        <PrismicRichText field={loading_animation_text?.richText} />
      </p>
    </div>
  );
};

export default Loading;
