import * as React from 'react';

import { container, definitionParagraph } from '../styles.module.css';

type DefinitionsType = {
  paragraphContent: { paragraph: string };
  definitions: { id: number; heading: string; desc: string }[];
};

const Definitions = ({
  paragraphContent,
  definitions,
}: DefinitionsType): JSX.Element => {
  return (
    <div className={container}>
      <h2>Definitions</h2>
      <p className={definitionParagraph}>{paragraphContent.paragraph}</p>
      <ul>
        {definitions.map((item) => (
          <li key={item.id}>
            <span>{item.heading}</span>
            {item.desc}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Definitions;
